
import React, { useRef, useState , useContext} from "react"
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import { loggedUser,updatePassword, updateEmail,updateProfile} from  "../components/FirebaseAuth"
import { Link, navigate } from "gatsby"
import { PageLayout } from "../components"
import { SEO } from "../utils"

import firebase from "../components/firebase"
import ThemeContext from "../utils/theme"

export default function UpdateProfile() 
{
  const nameRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef() 
  const passwordConfirmRef = useRef()
  const currentUser = loggedUser()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const { dark } = useContext(ThemeContext)


  function handleSubmit(e) 
  {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) 
    {
      return setError("Passwords do not match")
    }
    
    const promises = []
    setLoading(true)
    setError("")

    if (currentUser)
    {
      
      if (nameRef.current.value) 
      {
        promises.push(updateProfile(nameRef.current.value))
      }
      if (emailRef.current.value !== currentUser.email) 
      {
        promises.push(updateEmail(emailRef.current.value))
      }
      if (passwordRef.current.value) 
      {
        promises.push(updatePassword(passwordRef.current.value))
      }
    
      Promise.all(promises)
      .then(async () => 
      {
        let profileInfo = loggedUser()
        
        let previousData
        //console.log("RUNNING Users FIREBASE CALL")
        await firebase.firestore().collection('Users').doc(String(profileInfo.uid)).get()
        .then((doc) => {
          if (doc.exists) 
          {
              //console.log("Document data:", doc.data());
              //doc.data()
              previousData = doc.data()
          } 
          else 
          {
              // doc.data() will be undefined in this case
              console.log("No such user!");
          }
        }).catch((error) => {
            console.log("Error getting user:", error);
        });

        previousData["displayName"] =[0, String(profileInfo.displayName),String(profileInfo.email)]

        firebase.firestore().collection('Users').doc(String(profileInfo.uid)).set(previousData)

        let neededData = {"email":profileInfo.email,"displayName":profileInfo.displayName,"uid":profileInfo.uid}
        //console.log("profile is",neededData)
        localStorage.setItem('profileData', JSON.stringify(neededData) )
        navigate(`/Profile`)
      })
      .catch((e) => 
      {
        setError(`Failed to update account ${e && e.message}`)
      })
      .finally(() => 
      {
        setLoading(false)
      })
    }
  }

  return (
    <>
    <PageLayout>
      <SEO title="Sign Up" />
      <Container className="d-flex align-items-center justify-content-center" >
        <div className="w-100 text-color" style={{ maxWidth: "400px" }}>
          <Card style={{ borderColor:`${dark ? '#333' : '#ddd'}`,borderWidth:"3px" }}>
            <Card.Body>

              <h2 className="text-center mb-4">Update Profile</h2>
              {error && <Alert variant="danger">{error}</Alert>}

              <Form onSubmit={handleSubmit}>
                <Form.Group id="name">
                  <Form.Label>Display Name</Form.Label>
                  <Form.Control
                    type="text"
                    ref={nameRef}
                    required
                    defaultValue={currentUser ? currentUser.displayName : ""}
                  />
                </Form.Group>
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    ref={emailRef}
                    required
                    defaultValue={currentUser ? currentUser.email : ""}
                  />
                </Form.Group>
                <Form.Group id="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    ref={passwordRef}
                    placeholder="Leave blank to keep the same"
                  />
                </Form.Group>
                <Form.Group id="password-confirm">
                  <Form.Label>Password Confirmation</Form.Label>
                  <Form.Control
                    type="password"
                    ref={passwordConfirmRef}
                    placeholder="Leave blank to keep the same"
                  />
                </Form.Group>
                <br/>
                <Button disabled={loading} type="submit">
                  Update
                </Button>
              </Form>

            </Card.Body>
          </Card>

          <div className="w-100 text-center mt-2">
            <Link to="/Profile">Cancel</Link>
          </div>
        </div>
      </Container>
    </PageLayout>
    </>
  )
}